<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Right Col -->
        <b-navbar-nav class="nav align-items-center ml-auto">
            <darkToggler class="d-none d-lg-block" />
            <project-dropdown />
            <user-dropdown />
            <customizer />
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink,
} from 'bootstrap-vue'

import Customizer from '../../../../components/Misc/Customizer.vue'
import DarkToggler from './components/DarkToggler.vue'
import UserDropdown from './components/UserDropdown.vue'
import ProjectDropdown from './components/ProjectDropdown.vue'

export default {
    components: {
        BLink,
        // Navbar Components
        DarkToggler,
        UserDropdown,
        ProjectDropdown,
        Customizer,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },

}
</script>
