<template>
    <div class="navbar-header d-xl-block d-none">
        <ul class="nav navbar-nav">
            <li class="nav-item">
                <b-link
                    class="navbar-brand"
                    to="/"
                >
                    <span class="brand-logo">
                        <b-img
                            v-if="isDark"
                            :src="appLogoImageLight"
                            alt="logo"
                        />
                        <b-img
                            v-if="!isDark"
                            :src="appLogoImage"
                            alt="logo"
                        />
                    </span>
                </b-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
    components: {
        BLink,
        BImg,
    },
    setup() {
    // App Name
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')
        const { appName, appLogoImage, appLogoImageLight } = $themeConfig.app
        return {
            appName,
            appLogoImage,
            appLogoImageLight,
            isDark,
        }
    },
}
</script>

<style>
</style>
